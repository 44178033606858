import { useState, useEffect, useRef, lazy, Suspense, memo } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppTabs from 'common/components/AppTabs';
import SummaryInformation, { RefetchHandle } from './StudentSummaryInfo';
import StudentDetail from './StudentDetail';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import { TAB } from 'common/constants';

import './desktop.scss';
import NoteList from 'components/Note/NoteList';

const Enrollment = lazy(() => import('./Enrollment'));
const NotificationList = lazy(
  () => import('../../../components/notifications/NotificationList')
);
const AssessmentInStudentDetail = lazy(() => import('./Assessment'));
const Billings = lazy(() => import('./Billings'));

const StudentDetailRoute = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const ref = useRef<RefetchHandle>(null);

  const handleRefetch = () => {
    if (ref.current) {
      ref.current.refetch();
    }
  };

  const [tabIndex, setTabIndex] = useState<number>(TAB.IDLE);

  const tabs = [
    {
      name: 'Student Details',
      component: <StudentDetail onUpdated={handleRefetch} />
    },
    {
      name: 'Enrolment',
      component: <Enrollment onUpdated={handleRefetch} />
    },
    {
      name: 'Assessment',
      component: <AssessmentInStudentDetail />
    },
    {
      name: 'Billing',
      component: <Billings />
    },
    {
      name: 'Notification',
      component: <NotificationList />
    },
    {
      name: 'Notes',
      component: <NoteList />
    }
    // {
    //   name: 'Cards/Fob',
    //   component: <AppComingSoon />
    // }
  ];

  const handleSelectedTab = (val: number) => {
    setTabIndex(val);

    searchParams.set('indexRoute', `${val}`);
    setSearchParams(searchParams);
  };

  const indexRoute = Number(searchParams.get('indexRoute'));

  useEffect(() => {
    if (indexRoute >= 0 && indexRoute <= tabs.length) {
      setTabIndex(indexRoute);
    } else {
      setTabIndex(TAB.INIT);

      searchParams.set('indexRoute', `${TAB.INIT}`);
      setSearchParams(searchParams, { replace: true });
    }
  }, [indexRoute, searchParams, setSearchParams, tabs.length]);

  return (
    <div className="student_route">
      {tabIndex >= 0 ? (
        <>
          <AppBreadCrumb
            items={[
              { name: 'Students', href: '/students' },
              {
                name: tabs[tabIndex].name,
                href: location?.pathname + location?.search
              }
            ]}
          />

          <SummaryInformation ref={ref} />

          <div className="student_route-tab">
            <AppTabs
              tabs={tabs}
              activeTabIndex={tabIndex}
              onChangeTab={handleSelectedTab}
            />

            {tabIndex >= 0 ? (
              <Suspense fallback={<AppLoadingContainer />}>
                {tabs[tabIndex].component}
              </Suspense>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default memo(StudentDetailRoute);
