import { useForm } from 'react-hook-form';
import AppInput from 'common/components/AppInput';
import AppButton from 'common/components/AppButton';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { HiArrowLeft } from 'react-icons/hi2';
import './desktop.scss';
import AppTextArea from 'common/components/AppTextArea';
import dayjs from 'dayjs';
import { useBrandLocation } from 'context/BrandLocationContext';

import { CreateNoteDto } from 'DTOs/note.dto';
import { saveNote } from 'services/note.service';
import { useToast } from 'context/ToastContext';

interface NoteFormData {
  title: string;
  content: string;
}

interface Props {
  openAdd: boolean;
  onCloseAdd: () => void;
  intendedUserId: string;
  onSuccess: (createdNote: any) => void;
}

const schema = yup.object({
  title: yup.string().required('Title is required'),
  content: yup
    .string()
    .required('Note is required')
    .min(20, 'Note must be at least 20 characters long')
});

const NoteAdd = (props: Props) => {
  const { openAdd, onCloseAdd, intendedUserId, onSuccess } = props;
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { selectedLocation: __globalLocation } = useBrandLocation();

  useEffect(() => {
    if (!openAdd) {
      document.body.style.overflow = 'auto';
    } else {
      document.documentElement.style.cssText = 'scrollbar-gutter: stable';
      document.body.style.overflow = 'hidden';
    }
  }, [openAdd]);

  const handleCloseModal = () => {
    setDisabled(true);
    onCloseAdd();
  };

  const handleBack = () => {
    onCloseAdd();
  };

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset
  } = useForm<NoteFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: dayjs().tz('Australia/Sydney').format('DD-MM-YYYY HH:mm:ss'),
      content: ''
    },
    mode: 'onChange'
  });

  useEffect(() => {
    setDisabled(!isValid);
  }, [isValid]);

  const onSubmit = async (data: NoteFormData) => {
    if (!__globalLocation?._id || !intendedUserId) return;
    setLoading(true);

    const createNoteDto: CreateNoteDto = {
      content: data.content,
      title: data.title,
      intendedUserId: intendedUserId,
      generatedLocationId: __globalLocation?._id || ''
    };

    saveNote(createNoteDto)
      .then((response) => {
        reset();
        toast.success('Note saved successfully');
        onSuccess(response);
        onCloseAdd();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || 'Failed to save note');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="noteAddModal">
      <div
        className={`overlay ${openAdd ? 'active' : ' '}`}
        onClick={handleCloseModal}
      ></div>
      <div className={`noteForm ${openAdd ? 'active' : ' '}`}>
        <div className="noteForm__header">
          <HiArrowLeft
            size={24}
            style={{ cursor: 'pointer' }}
            onClick={handleBack}
          />
          <p>Add New Note </p>
          <div></div>
        </div>
        {openAdd ? (
          <div className="scrollable-container">
            <div className={`studentAddForm ${openAdd ? 'active' : ' '}`}>
              <div className="noteForm__content__header">
                <div className="noteForm__content disable-scroll">
                  <div className="noteForm__content__wrapper">
                    <AppInput
                      {...register('title')}
                      label="Title"
                      message={{
                        type: 'error',
                        text: errors.title?.message || ''
                      }}
                    />

                    <AppTextArea
                      {...register('content')}
                      label="Note"
                      rows={4}
                      message={{
                        type: 'error',
                        text: errors.content?.message || ''
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="noteForm__actions">
          <AppButton
            type="button"
            variant={disabled ? 'disabled' : 'primary'}
            onClick={handleSubmit(onSubmit)}
            isLoading={loading}
          >
            Save
          </AppButton>
          <AppButton
            type="button"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Cancel
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default NoteAdd;
