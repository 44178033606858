import { CreateNoteDto } from 'DTOs/note.dto';
import API from './API';

const NOTE_ENDPOINT = `/note`;

export const getNotes = async (params: Record<string, unknown>) => {
  const response = await API.get(`${NOTE_ENDPOINT}`, params);
  return response.data.data;
};

export const saveNote = async (payload: CreateNoteDto) => {
  const response = await API.post(`${NOTE_ENDPOINT}`, { body: payload });
  return response.data;
};

export const getNoteDetail = async (noteId: string) => {
  const response = await API.get(`${NOTE_ENDPOINT}/${noteId}`);
  return response.data.data;
};
